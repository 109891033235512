import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ErrorsState {
  errors: string[];
}

const initialState: ErrorsState = {
  errors: [],
};

export const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = [];
    },
    addError: (state, action: PayloadAction<string>) => {
      state.errors.push(action.payload);
    },
  },
});

export const { clearErrors, addError } = errorsSlice.actions;
