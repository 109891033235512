export function formatNumber(n: number): string {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(n);
}

export function formatRateInPerSecond(rate: number): string {
  return `${formatNumber(rate)}/s`;
}

export function formatRateInPerMinute(rate: number): string {
  return `${formatNumber(rate * 60)}/m`;
}

export function formatMW(kW: number): string {
  return `${formatNumber(kW / 1000)} MW`;
}

export function encodeBase64URL(binary: string): string {
  return btoa(binary)
    .replaceAll("+", "-")
    .replaceAll("/", "_")
    .replaceAll("=", "");
}

export function decodeBase64URL(base64: string): string {
  return atob(base64.replaceAll("-", "+").replaceAll("_", "/"));
}
