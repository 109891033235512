import { Requirement } from "./requirements";

export type ProducerName =
  | "Assembling machine 1"
  | "Assembling machine 2"
  | "Assembling machine 3"
  | "Boiler"
  | "Burner mining drill"
  | "Centrifuge"
  | "Chemical plant"
  | "Electric furnace"
  | "Electric mining drill"
  | "Heat exchanger"
  | "Nuclear reactor"
  | "Offshore pump"
  | "Oil refinery"
  | "Player"
  | "Pumpjack"
  | "Rocket silo"
  | "Steel furnace"
  | "Stone furnace";

export interface Producer {
  name: ProducerName;
  /** Energy consumption in kilojoules per second. */
  electricityConsumption?: number;
  /** Energy drain in kilojoules per second. */
  electricityDrain?: number;
  productionFactor: number;
  /** Pollution produced per second. */
  pollution?: number;
  moduleSlots?: number;
  requirements?: Requirement[];
}

const PRODUCERS: Producer[] = [
  {
    name: "Burner mining drill",
    // electricityConsumption: 150,
    productionFactor: 0.25,
    pollution: 12 * 60,
  },
  {
    name: "Electric mining drill",
    electricityConsumption: 90,
    productionFactor: 0.5,
    pollution: 10 * 60,
    moduleSlots: 3,
    requirements: ["Electric mining drill"],
  },
  {
    name: "Stone furnace",
    // electricityConsumption: 90,
    productionFactor: 1,
    pollution: 2 * 60,
  },
  {
    name: "Steel furnace",
    // electricityConsumption: 90,
    productionFactor: 2,
    pollution: 4 * 60,
    requirements: ["Advanced material processing"],
  },
  {
    name: "Electric furnace",
    electricityConsumption: 180,
    electricityDrain: 6,
    productionFactor: 2,
    pollution: 1 * 60,
    moduleSlots: 2,
    requirements: ["Advanced material processing 2"],
  },
  {
    name: "Assembling machine 1",
    electricityConsumption: 75,
    electricityDrain: 2.5,
    productionFactor: 0.5,
    pollution: 4 * 60,
    requirements: ["Automation"],
  },
  {
    name: "Assembling machine 2",
    electricityConsumption: 150,
    electricityDrain: 5,
    productionFactor: 0.75,
    pollution: 3 * 60,
    moduleSlots: 2,
    requirements: ["Automation 2"],
  },
  {
    name: "Assembling machine 3",
    electricityConsumption: 375,
    electricityDrain: 12.5,
    productionFactor: 1.25,
    pollution: 2 * 60,
    moduleSlots: 4,
    requirements: ["Automation 3"],
  },
  {
    name: "Pumpjack",
    electricityConsumption: 90,
    productionFactor: 1,
    pollution: 10 * 60,
    moduleSlots: 2,
  },
  {
    name: "Oil refinery",
    electricityConsumption: 420,
    electricityDrain: 14,
    productionFactor: 1,
    pollution: 6 * 60,
    moduleSlots: 3,
  },
  {
    name: "Chemical plant",
    electricityConsumption: 210,
    electricityDrain: 7,
    productionFactor: 1,
    pollution: 4 * 60,
    moduleSlots: 3,
  },
  {
    name: "Offshore pump",
    productionFactor: 1,
  },
  {
    name: "Rocket silo",
    electricityConsumption: 4000,
    productionFactor: 1,
    moduleSlots: 4,
  },
  {
    name: "Centrifuge",
    electricityConsumption: 350,
    electricityDrain: 11.6,
    productionFactor: 1,
    pollution: 4 * 60,
    moduleSlots: 2,
  },
  {
    name: "Player",
    productionFactor: 1,
  },
  {
    name: "Nuclear reactor",
    productionFactor: 1,
  },
  {
    name: "Boiler",
    productionFactor: 1,
    pollution: 30 * 60,
  },
  {
    name: "Heat exchanger",
    // A Steam turbine consumes 60 Steam/s and produces 5.82 MW of energy,
    // therefore one unit of Steam contains 0.097 MJ of energy (5.82 MW / 60
    // Steam/s). A Heat exchanger produces 10 MW of energy, therefore it
    // consumes ~103.09 Steam/s (10 MW / 0.097 MJ). Since a Boiler produces 60
    // Steam/s with a production factor of 1, a Heat exchanger has a production
    // factor of ~1.72 (~103.09 Steam/s / 60 Steam/s).
    productionFactor: 10 / (5.82 / 60) / 60,
    requirements: ["Heat exchanger"],
  },
];

export function getProducers(): Producer[] {
  return PRODUCERS.slice();
}

export function getProducer(producerName: ProducerName): Producer {
  return PRODUCERS.find((producer) => producer.name === producerName)!;
}
