import { ItemName } from "../data/item-names";
import { RecipeName } from "../data/recipes";
import { NameDisplay } from "./name-display";

export interface ItemDisplayProps {
  itemName: ItemName;
  recipeName?: RecipeName;
  padLeft?: boolean;
  breakLine?: boolean;
}

export function ItemDisplay({
  itemName,
  recipeName,
  padLeft,
  breakLine,
}: ItemDisplayProps): JSX.Element {
  return (
    <>
      <NameDisplay name={itemName} padLeft={padLeft} />
      {recipeName && recipeName !== itemName ? (
        <>
          {" "}
          using{breakLine ? <br /> : null}
          <NameDisplay name={recipeName} padLeft={!breakLine} />
        </>
      ) : null}
    </>
  );
}
