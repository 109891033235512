// import Bootstrap from "bootstrap";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { calculate } from "./calculator";
import { BreakdownsDisplay } from "./components/breakdowns-display";
import { InputForm } from "./components/input-form";
import { ProducersDisplay } from "./components/producers-display";
import { RecipesDisplay } from "./components/recipes-display";
import { useAppSelector } from "./state/hooks";
import { addStateListener, loadState } from "./state/persistence";
import { store } from "./state/store";

function App(): JSX.Element {
  const breakdowns = useAppSelector((state) => state.output.breakdowns);

  useEffect(() => {
    loadState();
    addStateListener();
    calculate();
  }, []);

  return (
    <div className="container-fluid">
      <h1>Factorio Calculator</h1>
      <div className="row">
        <div className="col">
          <InputForm />
          <h2>Breakdown</h2>
          <BreakdownsDisplay breakdowns={breakdowns} />
        </div>
        <div className="col-5">
          <h2>Recipes</h2>
          <RecipesDisplay />
          <h2>Producers</h2>
          <ProducersDisplay />
        </div>
      </div>
    </div>
  );
}

const container = document.getElementById("app") as HTMLDivElement;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
