import { calculate } from "../calculator";
import { decodeBase64URL, encodeBase64URL } from "../util";
import { InputState, setInput } from "./input";
import { store } from "./store";

const CURRENT_VERSION = 2;
const LOCAL_STORAGE_KEY = "state";

interface LocalState {
  version: number;
  input: InputState;
}

export function loadState(): void {
  try {
    let json: string | undefined | null;
    if (location.hash.length >= 2) {
      const base64URL = location.hash.substring(1);
      json = decodeBase64URL(base64URL);
    } else {
      json = localStorage.getItem(LOCAL_STORAGE_KEY);
    }

    if (json != null) {
      const { version, input } = JSON.parse(json) as LocalState;
      if (version === CURRENT_VERSION) {
        store.dispatch(setInput(input));
        calculate({ skipSaveState: true });
      }
    }
  } catch {
    // no-op
  }
}

export function saveState(): void {
  const { input } = store.getState();
  const json = JSON.stringify({ version: CURRENT_VERSION, input });
  const base64URL = encodeBase64URL(json);
  history.pushState(input, "", "#" + base64URL);
  localStorage.setItem(LOCAL_STORAGE_KEY, json);
}

export function addStateListener(): void {
  window.addEventListener("popstate", () => {
    loadState();
    calculate({ skipSaveState: true });
  });
}
