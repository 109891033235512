import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { setDesiredRate } from "../state/input";

export interface RateInputProps {
  index: number;
}

export function RateInput({ index }: RateInputProps): JSX.Element {
  const { desiredRate, desiredRateUnits } = useAppSelector(
    (state) => state.input.desiredItems[index],
  );
  const dispatch = useAppDispatch();
  const [valueAsString, setValueAsString] = useState<string | undefined>();

  return (
    <input
      type="number"
      className="form-control flex-grow-0"
      style={{ width: "6rem" }}
      placeholder="Desired rate"
      value={valueAsString ?? desiredRate}
      min={0}
      step={desiredRateUnits === "per-second" ? 0.1 : 1}
      onChange={(event) => {
        const value = event.currentTarget.valueAsNumber;
        console.log(value);
        if (isNaN(value)) {
          setValueAsString(event.currentTarget.value);
        } else {
          setValueAsString(undefined);
          dispatch(
            setDesiredRate({
              index,
              desiredRate: event.currentTarget.valueAsNumber,
              desiredRateUnits,
            }),
          );
        }
      }}
    />
  );
}
