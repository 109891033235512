import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ItemName } from "../data/item-names";
import { Module, ModuleName } from "../data/modules";
import { Producer, ProducerName } from "../data/producers";
import { Recipe } from "../data/recipes";

export interface Breakdown {
  itemName: ItemName;
  desiredRate: number;
  recipe: Recipe;
  producerConfig: ProducerConfig;
  input: Breakdown[];
}

export interface RecipeConfig {
  recipe: Recipe;
  producerConfig: ProducerConfig;
}

export interface ProducerConfig {
  producer: Producer;
  partialAmount: number;
  actualAmount: number;
  isElectric: boolean;
  electricityUsage: number;
  pollution: number;
  modules: Module[];
  modulesEnergyFactor: number;
  modulesProductionFactor: number;
  modulesSpeedFactor: number;
}

export interface RecipesEntry {
  itemName: ItemName;
  rate: number;
  recipeConfig: RecipeConfig;
}

export interface ProducersEntry {
  producerName: ProducerName;
  producerAmount: number;
}

export interface ModulesEntry {
  moduleName: ModuleName;
  moduleCount: number;
}

export interface OutputState {
  breakdowns: Breakdown[];
  recipes: RecipesEntry[];
  producers: ProducersEntry[];
  modules: ModulesEntry[];
}

const initialState: OutputState = {
  breakdowns: [],
  recipes: [],
  producers: [],
  modules: [],
};

export const outputSlice = createSlice({
  name: "output",
  initialState,
  reducers: {
    setBreakdowns: (state, action: PayloadAction<Breakdown[]>) => {
      state.breakdowns = action.payload;
    },
    setRecipes: (state, action: PayloadAction<RecipesEntry[]>) => {
      state.recipes = action.payload;
    },
    setProducers: (state, action: PayloadAction<ProducersEntry[]>) => {
      state.producers = action.payload;
    },
    setModules: (state, action: PayloadAction<ModulesEntry[]>) => {
      state.modules = action.payload;
    },
  },
});

export const { setBreakdowns, setRecipes, setProducers, setModules } =
  outputSlice.actions;
