import { ItemName } from "../data/item-names";
import { ProducerName } from "../data/producers";
import { RecipeName } from "../data/recipes";
import { Requirement } from "../data/requirements";

export interface IconProps {
  name: Requirement | ItemName | RecipeName | ProducerName;
  padLeft?: boolean;
  padRight?: boolean;
}

export function Icon({ name, padLeft, padRight }: IconProps): JSX.Element {
  return (
    <img
      src={`assets/${name}.png`}
      alt={name}
      title={name}
      style={{
        height: "1.5rem",
        paddingLeft: padLeft ? "0.5rem" : undefined,
        paddingRight: padRight ? "0.5rem" : undefined,
      }}
    />
  );
}
