import { calculate } from "../calculator";
import { DesiredItem, DesiredRateUnits } from "../data/desired-item";
import { ItemName, getItemNames } from "../data/item-names";
import { getRequirements } from "../data/requirements";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import {
  addItem,
  moveItemDown,
  moveItemUp,
  removeItem,
  setDesiredRate,
  setItemName,
  toggleItem,
  toggleRequirement,
} from "../state/input";
import { Icon } from "./icon";
import { NameDisplay } from "./name-display";
import { RateInput } from "./rate-input";

const allRequirements = getRequirements();
const allItemNames = getItemNames();

export function InputForm(): JSX.Element {
  const requirementsMet = useAppSelector(
    (state) => state.input.requirementsMet,
  );
  const desiredItems = useAppSelector((state) => state.input.desiredItems).map(
    (init) => new DesiredItem(init),
  );
  const { errors } = useAppSelector((state) => state.errors);
  const dispatch = useAppDispatch();

  return (
    <form
      onChange={() => {
        calculate();
      }}
      onSubmit={(event) => event.preventDefault()}
      noValidate
    >
      <div className="row">
        <div className="col-auto">
          <h2>Requirements</h2>
          {allRequirements.map((requirement, index) => {
            const id = `requirement-${index}`;
            return (
              <div className="form-check form-switch" key={requirement}>
                <input
                  type="checkbox"
                  id={id}
                  className="form-check-input"
                  checked={requirementsMet.includes(requirement)}
                  onChange={() => {
                    dispatch(toggleRequirement(requirement));
                  }}
                />
                <label htmlFor={id} className="form-check-label">
                  <NameDisplay name={requirement} />
                </label>
              </div>
            );
          })}
        </div>
        <div className="col">
          <h2>
            Items{" "}
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                dispatch(addItem());
                calculate();
              }}
            >
              <i className="bi bi-plus-circle" />
            </button>
          </h2>
          {desiredItems.map(
            ({ isEnabled, itemName, desiredRate, desiredRateUnits }, index) => (
              <div className="input-group flex-nowrap" key={index}>
                <div className="form-control flex-grow-0 w-auto">
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isEnabled}
                      onChange={() => {
                        dispatch(toggleItem({ index }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-control flex-grow-0 w-auto">
                  <Icon name={itemName} padRight={false} />
                </div>
                <select
                  className="form-select flex-grow-0 w-auto"
                  value={itemName}
                  autoFocus={index === 0}
                  onChange={(event) => {
                    dispatch(
                      setItemName({
                        index,
                        itemName: event.currentTarget.value as ItemName,
                      }),
                    );
                  }}
                >
                  {allItemNames.map((itemName) => (
                    <option key={itemName} value={itemName}>
                      {itemName}
                    </option>
                  ))}
                </select>
                <RateInput index={index} />
                <select
                  className="form-select flex-grow-0 w-auto"
                  value={desiredRateUnits}
                  onChange={(event) => {
                    dispatch(
                      setDesiredRate({
                        index,
                        desiredRate,
                        desiredRateUnits: event.currentTarget
                          .value as DesiredRateUnits,
                      }),
                    );
                  }}
                >
                  <option value="per-second">/s</option>
                  <option value="per-minute">/m</option>
                </select>
                <button
                  className="btn btn-outline-secondary"
                  disabled={index <= 0}
                  onClick={() => {
                    dispatch(moveItemUp({ index }));
                    calculate();
                  }}
                >
                  <i className="bi bi-arrow-up"></i>
                </button>
                <button
                  className="btn btn-outline-secondary"
                  disabled={index >= desiredItems.length - 1}
                  onClick={() => {
                    dispatch(moveItemDown({ index }));
                    calculate();
                  }}
                >
                  <i className="bi bi-arrow-down"></i>
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    dispatch(removeItem({ index }));
                    calculate();
                  }}
                >
                  <i className="bi bi-trash3" />
                </button>
              </div>
            ),
          )}
        </div>
        {errors.map((err, index) => (
          <div className="alert alert-danger mt-3" key={index}>
            {err}
          </div>
        ))}
      </div>
    </form>
  );
}
