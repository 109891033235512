import { Breakdown } from "../state/output";
import { formatNumber } from "../util";
import { ItemDisplay } from "./item-display";
import { NameDisplay } from "./name-display";
import { Rate } from "./rate";

export interface BreakdownsDisplayProps {
  breakdowns: Breakdown[];
}

export function BreakdownsDisplay({
  breakdowns,
}: BreakdownsDisplayProps): JSX.Element {
  return (
    <ul>
      {breakdowns.map((breakdown, index) => (
        <BreakdownDisplay breakdown={breakdown} key={index} />
      ))}
    </ul>
  );
}

interface BreakdownDisplayProps {
  breakdown: Breakdown;
}

function BreakdownDisplay({
  breakdown: { itemName, desiredRate, recipe, producerConfig, input },
}: BreakdownDisplayProps): JSX.Element {
  return (
    <li>
      <ItemDisplay itemName={itemName} recipeName={recipe.name} /> @{" "}
      <Rate rate={desiredRate} /> from
      <NameDisplay name={producerConfig.producer.name} padLeft /> x
      {formatNumber(producerConfig.partialAmount)}
      {input.length > 0 ? <BreakdownsDisplay breakdowns={input} /> : null}
    </li>
  );
}
