import { configureStore } from "@reduxjs/toolkit";
import { errorsSlice } from "./errors";
import { inputSlice } from "./input";
import { outputSlice } from "./output";

export const store = configureStore({
  reducer: {
    input: inputSlice.reducer,
    output: outputSlice.reducer,
    errors: errorsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
