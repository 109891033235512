import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DesiredItem,
  DesiredItemInit,
  DesiredRateUnits,
} from "../data/desired-item";
import { ItemName } from "../data/item-names";
import { Requirement } from "../data/requirements";

export interface InputState {
  requirementsMet: Requirement[];
  desiredItems: DesiredItemInit[];
}

const initialState: InputState = {
  requirementsMet: ["Electric mining drill", "Automation"],
  desiredItems: [new DesiredItem().toJSON()],
};

export const inputSlice = createSlice({
  name: "input",
  initialState,
  reducers: {
    toggleRequirement: (state, action: PayloadAction<Requirement>) => {
      const index = state.requirementsMet.indexOf(action.payload);
      if (index >= 0) {
        state.requirementsMet.splice(index, 1);
      } else {
        state.requirementsMet.push(action.payload);
      }
    },
    addItem: (state) => {
      state.desiredItems.push(new DesiredItem().toJSON());
    },
    removeItem: (state, action: PayloadAction<{ index: number }>) => {
      state.desiredItems.splice(action.payload.index, 1);
    },
    toggleItem: (state, action: PayloadAction<{ index: number }>) => {
      const item = state.desiredItems[action.payload.index];
      item.isEnabled = !item.isEnabled;
    },
    setItemName: (
      state,
      action: PayloadAction<{ index: number; itemName: ItemName }>,
    ) => {
      const item = state.desiredItems[action.payload.index];
      item.itemName = action.payload.itemName;
    },
    setDesiredRate: (
      state,
      action: PayloadAction<{
        index: number;
        desiredRate: number;
        desiredRateUnits: DesiredRateUnits;
      }>,
    ) => {
      const item = state.desiredItems[action.payload.index];
      item.desiredRate = action.payload.desiredRate;
      if (item.desiredRateUnits !== action.payload.desiredRateUnits) {
        if (item.desiredRateUnits === "per-second") {
          item.desiredRate *= 60;
        } else {
          item.desiredRate /= 60;
        }

        item.desiredRateUnits = action.payload.desiredRateUnits;
      }
    },
    // setDesiredRateUnits: (
    //   state,
    //   action: PayloadAction<{
    //     index: number;
    //     desiredRateUnits: DesiredRateUnits;
    //   }>,
    // ) => {
    //   const item = state.desiredItems[action.payload.index];
    //   if (item.desiredRateUnits !== action.payload.desiredRateUnits) {
    //     if (item.desiredRateUnits === "per-second") {
    //       item.desiredRate *= 60;
    //     } else {
    //       item.desiredRate /= 60;
    //     }

    //     item.desiredRateUnits = action.payload.desiredRateUnits;
    //   }
    // },
    moveItemUp: (state, action: PayloadAction<{ index: number }>) => {
      if (action.payload.index <= 0) {
        return;
      }

      const [item] = state.desiredItems.splice(action.payload.index, 1);
      state.desiredItems.splice(action.payload.index - 1, 0, item);
    },
    moveItemDown: (state, action: PayloadAction<{ index: number }>) => {
      if (action.payload.index >= state.desiredItems.length - 1) {
        return;
      }

      const [item] = state.desiredItems.splice(action.payload.index, 1);
      state.desiredItems.splice(action.payload.index + 1, 0, item);
    },
    setInput: (state, action: PayloadAction<InputState>) => {
      state.requirementsMet = action.payload.requirementsMet;
      state.desiredItems = action.payload.desiredItems;
    },
  },
});

export const {
  toggleRequirement,
  addItem,
  removeItem,
  toggleItem,
  setItemName,
  setDesiredRate,
  moveItemUp,
  moveItemDown,
  setInput,
} = inputSlice.actions;
