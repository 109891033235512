import { ItemName } from "./item-names";

const ITEM_NAMES_IN_ORDER: ItemName[] = [
  "Wooden chest",
  "Iron chest",
  "Steel chest",
  "Storage tank",
  "Transport belt",
  "Fast transport belt",
  "Express transport belt",
  "Underground belt",
  "Fast underground belt",
  "Express underground belt",
  "Splitter",
  "Fast splitter",
  "Express splitter",
  "Burner inserter",
  "Inserter",
  "Long-handed inserter",
  "Fast inserter",
  "Filter inserter",
  "Stack inserter",
  "Stack filter inserter",
  "Small electric pole",
  "Medium electric pole",
  "Big electric pole",
  "Substation",
  "Pipe",
  "Pipe to ground",
  "Pump",
  "Rail",
  "Rail signal",
  "Rail chain signal",
  "Locomotive",
  "Cargo wagon",
  "Fluid wagon",
  "Artillery wagon",
  "Car",
  "Tank",
  "Spidertron",
  "Spidertron remote",
  "Logistic robot",
  "Construction robot",
  "Active provider chest",
  "Passive provider chest",
  "Storage chest",
  "Buffer chest",
  "Requester chest",
  "Roboport",
  "Lamp",
  "Red wire",
  "Green wire",
  "Arithmetic combinator",
  "Decider combinator",
  "Constant combinator",
  "Power switch",
  "Programmable speaker",
  "Stone brick",
  "Concrete",
  "Hazard concrete",
  "Refined concrete",
  "Refined hazard concrete",
  "Landfill",
  "Cliff explosives",
  "Repair pack",
  "Blueprint",
  "Deconstruction planner",
  "Upgrade planner",
  "Blueprint book",
  "Boiler",
  "Steam engine",
  "Solar panel",
  "Accumulator",
  "Nuclear reactor",
  "Heat pipe",
  "Heat exchanger",
  "Steam turbine",
  "Burner mining drill",
  "Electric mining drill",
  "Offshore pump",
  "Pumpjack",
  "Stone furnace",
  "Steel furnace",
  "Electric furnace",
  "Assembling machine 1",
  "Assembling machine 2",
  "Assembling machine 3",
  "Oil refinery",
  "Chemical plant",
  "Centrifuge",
  "Lab",
  "Beacon",
  "Speed module",
  "Speed module 2",
  "Speed module 3",
  "Efficiency module",
  "Efficiency module 2",
  "Efficiency module 3",
  "Productivity module",
  "Productivity module 2",
  "Productivity module 3",
  "Rocket silo",
  "Satellite",
  "Crude oil",
  "Heavy oil",
  "Light oil",
  "Lubricant",
  "Petroleum gas",
  "Sulfuric acid",
  "Water",
  "Steam",
  "Wood",
  "Coal",
  "Stone",
  "Iron ore",
  "Copper ore",
  "Uranium ore",
  "Raw fish",
  "Iron plate",
  "Copper plate",
  "Solid fuel",
  "Steel plate",
  "Plastic bar",
  "Sulfur",
  "Battery",
  "Explosives",
  "Copper cable",
  "Iron stick",
  "Iron gear wheel",
  "Empty barrel",
  "Electronic circuit",
  "Advanced circuit",
  "Processing unit",
  "Engine unit",
  "Electric engine unit",
  "Flying robot frame",
  "Rocket part",
  "Rocket control unit",
  "Low density structure",
  "Rocket fuel",
  "Nuclear fuel",
  "Uranium-235",
  "Uranium-238",
  "Uranium fuel cell",
  "Automation science pack",
  "Logistic science pack",
  "Military science pack",
  "Chemical science pack",
  "Production science pack",
  "Utility science pack",
  "Space science pack",
  "Pistol",
  "Submachine gun",
  "Shotgun",
  "Combat shotgun",
  "Rocket launcher",
  "Flamethrower",
  "Land mine",
  "Firearm magazine",
  "Piercing rounds magazine",
  "Uranium rounds magazine",
  "Shotgun shells",
  "Piercing shotgun shells",
  "Cannon shell",
  "Explosive cannon shell",
  "Uranium cannon shell",
  "Explosive uranium cannon shell",
  "Artillery shell",
  "Rocket",
  "Explosive rocket",
  "Atomic bomb",
  "Flamethrower ammo",
  "Grenade",
  "Cluster grenade",
  "Poison capsule",
  "Slowdown capsule",
  "Defender capsule",
  "Distractor capsule",
  "Destroyer capsule",
  "Light armor",
  "Heavy armor",
  "Modular armor",
  "Power armor",
  "Power armor MK2",
  "Portable solar panel",
  "Portable fusion reactor",
  "Personal battery",
  "Personal battery MK2",
  "Belt immunity equipment",
  "Exoskeleton",
  "Personal roboport",
  "Personal roboport MK2",
  "Nightvision",
  "Energy shield",
  "Energy shield MK2",
  "Personal laser defense",
  "Discharge defense",
  "Discharge defense remote",
  "Wall",
  "Gate",
  "Gun turret",
  "Laser turret",
  "Flamethrower turret",
  "Artillery turret",
  "Artillery targeting remote",
  "Radar",
  "Player",
];

export function getItemNamesInOrder(): ItemName[] {
  return ITEM_NAMES_IN_ORDER.slice();
}

export function compareItemNames(a: ItemName, b: ItemName): number {
  const aIndex = ITEM_NAMES_IN_ORDER.indexOf(a);
  const bIndex = ITEM_NAMES_IN_ORDER.indexOf(b);

  return aIndex >= 0 && bIndex < 0
    ? -1
    : aIndex < 0 && bIndex >= 0
      ? 1
      : aIndex < bIndex
        ? -1
        : aIndex > bIndex
          ? 1
          : 0;
}
