import { DesiredItem } from "./data/desired-item";
import { ItemName } from "./data/item-names";
import { getItemNamesInOrder } from "./data/item-names-in-order";
import { Module, getModules } from "./data/modules";
import { getProducer } from "./data/producers";
import { Recipe, getRecipesByOutput } from "./data/recipes";
import { Requirement } from "./data/requirements";
import { addError, clearErrors } from "./state/errors";
import {
  Breakdown,
  ProducerConfig,
  ProducersEntry,
  RecipeConfig,
  RecipesEntry,
  setBreakdowns,
  setProducers,
  setRecipes,
} from "./state/output";
import { saveState } from "./state/persistence";
import { store } from "./state/store";

export interface CalculateOptions {
  skipSaveState?: boolean;
}

export function calculate({ skipSaveState }: CalculateOptions = {}): void {
  store.dispatch(clearErrors());

  const {
    input: { requirementsMet, desiredItems },
  } = store.getState();
  try {
    const breakdowns: Breakdown[] = [];
    for (const { itemName, desiredRateInItemsPerSecond } of desiredItems
      .filter(({ isEnabled }) => isEnabled)
      .map((init) => new DesiredItem(init))) {
      const breakdown = getBreakdown(
        itemName,
        desiredRateInItemsPerSecond,
        requirementsMet,
      );
      breakdowns.push(breakdown);
    }

    const recipesEntries = getRecipesEntries(breakdowns);
    const producersEntries = getProducersEntries(recipesEntries);

    store.dispatch(setBreakdowns(breakdowns));
    store.dispatch(setRecipes(recipesEntries));
    store.dispatch(setProducers(producersEntries));
  } catch (err) {
    if (err instanceof Error) {
      store.dispatch(addError(err.message));
      store.dispatch(setBreakdowns([]));
      store.dispatch(setRecipes([]));
      store.dispatch(setProducers([]));
    } else {
      console.error(err);
    }
  }

  if (!skipSaveState) {
    saveState();
  }
}

function getBreakdown(
  itemName: ItemName,
  desiredRate: number,
  requirementsMet: Requirement[],
): Breakdown {
  const recipeConfig = getBestRecipeConfig(
    itemName,
    desiredRate,
    requirementsMet,
  );

  if (recipeConfig == null) {
    throw new Error(
      `Missing at least one requirement for ${itemName}: ${getMissingRequirements(
        itemName,
        requirementsMet,
      ).join(", ")}`,
    );
  }

  const { recipe, producerConfig } = recipeConfig;

  const input =
    recipe.input == null
      ? []
      : (Object.entries(recipe.input) as [ItemName, number][]).map(
          ([inputItemName, amount]) =>
            getBreakdown(
              inputItemName,
              ((amount / producerConfig.modulesProductionFactor) *
                desiredRate) /
                recipe.output[itemName]!,
              requirementsMet,
            ),
        );

  return {
    itemName,
    desiredRate,
    recipe,
    producerConfig,
    input,
  };
}

function getBestRecipeConfig(
  itemName: ItemName,
  desiredRate: number,
  requirementsMet: Requirement[],
): RecipeConfig | undefined {
  return getRecipesByOutput(itemName, requirementsMet)
    .map((recipe) => ({
      recipe,
      producerConfig: getBestProducerConfig(
        recipe,
        itemName,
        desiredRate,
        requirementsMet,
      ),
    }))
    .filter(({ producerConfig }) => producerConfig != null)
    .sort((a, b) =>
      compareProducerConfigs(a.producerConfig!, b.producerConfig!),
    )[0] as RecipeConfig | undefined;
}

function getBestProducerConfig(
  recipe: Recipe,
  itemName: ItemName,
  desiredRate: number,
  requirementsMet: Requirement[],
): ProducerConfig | undefined {
  const { time, output } = recipe;
  return recipe.producers
    .map(getProducer)
    .filter(
      ({ requirements }) =>
        requirements == null ||
        requirements.every((requirement) =>
          requirementsMet.includes(requirement),
        ),
    )
    .flatMap((producer) =>
      getAllModuleCombinations(producer.moduleSlots ?? 0, requirementsMet).map(
        (modules) => ({
          producer,
          modules,
        }),
      ),
    )
    .map(({ producer, modules }) => {
      const {
        productionFactor,
        electricityConsumption,
        electricityDrain,
        pollution,
      } = producer;

      const { engeryModifier, productionModifier, speedModifier } =
        modules.reduce(
          ({ engeryModifier, productionModifier, speedModifier }, module) => ({
            engeryModifier: engeryModifier + (module.engeryModifier ?? 0),
            productionModifier:
              productionModifier + (module.productionModifier ?? 0),
            speedModifier: speedModifier + (module.speedModifier ?? 0),
          }),
          { engeryModifier: 0, productionModifier: 0, speedModifier: 0 },
        );

      const modulesEnergyFactor = Math.max(1 + engeryModifier, 0.2);
      const modulesProductionFactor = 1 + productionModifier;
      const modulesSpeedFactor = 1 + speedModifier;

      const partialAmount =
        desiredRate /
        (((output[itemName]! * productionFactor * modulesProductionFactor) /
          time) *
          modulesSpeedFactor);
      const actualAmount = Math.ceil(partialAmount);
      const electricityUsage =
        ((electricityConsumption ?? 0) * partialAmount +
          (electricityDrain ?? 0) * actualAmount) *
        modulesEnergyFactor;
      return {
        producer,
        modules,
        partialAmount,
        actualAmount,
        isElectric: (producer.electricityConsumption ?? 0) > 0,
        electricityUsage,
        pollution: (pollution ?? 0) * partialAmount,
        modulesEnergyFactor,
        modulesProductionFactor,
        modulesSpeedFactor,
      };
    })
    .sort(compareProducerConfigs)[0];
}

function getModuleCombinations(
  moduleCount: number,
  modules: Module[],
  index = 0,
  current: Module[] = [],
): Module[][] {
  const combinations: Module[][] = [];
  if (current.length === moduleCount) {
    combinations.push(current);
    return combinations;
  }

  for (let i = index; i < modules.length; i++) {
    combinations.push(
      ...getModuleCombinations(moduleCount, modules, i, [
        ...current,
        modules[i],
      ]),
    );
  }

  return combinations;
}

function getAllModuleCombinations(
  moduleSlots: number,
  requirementsMet: Requirement[],
): Module[][] {
  const modules = getModules().filter(({ requirements }) =>
    requirements.every((requirement) => requirementsMet.includes(requirement)),
  );

  const combinations: Module[][] = [];
  for (let moduleCount = 0; moduleCount <= moduleSlots; moduleCount++) {
    combinations.push(...getModuleCombinations(moduleCount, modules));
  }
  return combinations;
}

function compareProducerConfigs(a: ProducerConfig, b: ProducerConfig): number {
  return a.actualAmount < b.actualAmount
    ? -1
    : a.actualAmount > b.actualAmount
      ? 1
      : a.pollution < b.pollution
        ? -1
        : a.pollution > b.pollution
          ? 1
          : a.modulesProductionFactor > b.modulesProductionFactor
            ? -1
            : a.modulesProductionFactor < b.modulesProductionFactor
              ? 1
              : a.isElectric && !b.isElectric
                ? -1
                : !a.isElectric && b.isElectric
                  ? 1
                  : a.electricityUsage < b.electricityUsage
                    ? -1
                    : a.electricityUsage > b.electricityUsage
                      ? 1
                      : 0;
}

function getMissingRequirements(
  itemName: ItemName,
  requirementsMet: Requirement[],
): Requirement[] {
  const recipes = getRecipesByOutput(itemName);
  return recipes
    .flatMap(({ requirements }) => requirements ?? [])
    .concat(
      recipes
        .flatMap(({ producers }) => producers)
        .map(getProducer)
        .flatMap(({ requirements }) => requirements ?? []),
    )
    .filter((requirement) => !requirementsMet.includes(requirement))
    .reduce(
      (requirements, requirement) =>
        requirements.includes(requirement)
          ? requirements
          : [...requirements, requirement],
      [] as Requirement[],
    );
}

function getRecipesEntries(
  breakdowns: Breakdown[],
  recipesEntries: RecipesEntry[] = [],
): RecipesEntry[] {
  for (const breakdown of breakdowns) {
    const entry = recipesEntries.find(
      (entry) =>
        entry.itemName === breakdown.itemName &&
        entry.recipeConfig.recipe.name === breakdown.recipe.name &&
        entry.recipeConfig.producerConfig.producer.name ===
          breakdown.producerConfig.producer.name,
    );

    if (entry != null) {
      entry.rate += breakdown.desiredRate;
      entry.recipeConfig.producerConfig.partialAmount +=
        breakdown.producerConfig.partialAmount;
      entry.recipeConfig.producerConfig.actualAmount = Math.ceil(
        entry.recipeConfig.producerConfig.partialAmount,
      );
      entry.recipeConfig.producerConfig.electricityUsage +=
        breakdown.producerConfig.electricityUsage;
    } else {
      recipesEntries.push({
        itemName: breakdown.itemName,
        rate: breakdown.desiredRate,
        recipeConfig: {
          recipe: breakdown.recipe,
          producerConfig: breakdown.producerConfig,
        },
      });
    }

    for (const input of breakdown.input) {
      getRecipesEntries([input], recipesEntries);
    }
  }

  return recipesEntries.sort((a, b) => {
    const aBreakdownIndex = breakdowns
      .map(({ itemName }) => itemName)
      .indexOf(a.itemName);
    const bBreakdownIndex = breakdowns
      .map(({ itemName }) => itemName)
      .indexOf(b.itemName);
    const orderedItemNames = getItemNamesInOrder();
    const aItemIndex = orderedItemNames.indexOf(a.itemName);
    const bItemsIndex = orderedItemNames.indexOf(b.itemName);
    return aBreakdownIndex >= 0 && bBreakdownIndex < 0
      ? -1
      : aBreakdownIndex < 0 && bBreakdownIndex >= 0
        ? 1
        : aBreakdownIndex < bBreakdownIndex
          ? -1
          : aBreakdownIndex > bBreakdownIndex
            ? 1
            : aItemIndex >= 0 && bItemsIndex < 0
              ? -1
              : aItemIndex < 0 && bItemsIndex >= 0
                ? 1
                : aItemIndex < bItemsIndex
                  ? -1
                  : aItemIndex > bItemsIndex
                    ? 1
                    : 0;
  });
}

function getProducersEntries(recipeEntries: RecipesEntry[]): ProducersEntry[] {
  const producerEntries: ProducersEntry[] = [];

  for (const recipeEntry of recipeEntries) {
    const producerEntry = producerEntries.find(
      (entry) =>
        entry.producerName ===
        recipeEntry.recipeConfig.producerConfig.producer.name,
    );

    if (producerEntry != null) {
      producerEntry.producerAmount +=
        recipeEntry.recipeConfig.producerConfig.actualAmount;
    } else {
      producerEntries.push({
        producerName: recipeEntry.recipeConfig.producerConfig.producer.name,
        producerAmount: recipeEntry.recipeConfig.producerConfig.actualAmount,
      });
    }
  }

  return producerEntries.sort((a, b) => {
    const orderedItemNames = getItemNamesInOrder();
    const aIndex = orderedItemNames.indexOf(a.producerName);
    const bIndex = orderedItemNames.indexOf(b.producerName);
    return aIndex >= 0 && bIndex < 0
      ? -1
      : aIndex < 0 && bIndex >= 0
        ? 1
        : aIndex < bIndex
          ? -1
          : aIndex > bIndex
            ? 1
            : 0;
  });
}
