import { formatRateInPerMinute, formatRateInPerSecond } from "../util";

export interface RateProps {
  rate: number;
}

export function Rate({ rate }: RateProps): JSX.Element {
  return (
    <span title={formatRateInPerSecond(rate)}>
      {formatRateInPerMinute(rate)}
    </span>
  );
}
