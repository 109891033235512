import { useAppSelector } from "../state/hooks";
import { formatMW, formatNumber } from "../util";
import { Icon } from "./icon";
import { ItemDisplay } from "./item-display";
import { NameDisplay } from "./name-display";
import { Rate } from "./rate";

export function RecipesDisplay(): JSX.Element {
  const entries = useAppSelector((state) => state.output.recipes);
  const { desiredRateTotal, electricityUsageTotal, pollutionTotal } =
    entries.reduce(
      (
        { desiredRateTotal, electricityUsageTotal, pollutionTotal },
        {
          rate,
          recipeConfig: {
            producerConfig: { electricityUsage, pollution },
          },
        },
      ) => ({
        desiredRateTotal: desiredRateTotal + rate,
        electricityUsageTotal: electricityUsageTotal + electricityUsage,
        pollutionTotal: pollutionTotal + pollution,
      }),
      {
        desiredRateTotal: 0,
        electricityUsageTotal: 0,
        pollutionTotal: 0,
      },
    );

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Item</th>
          <th>Rate</th>
          <th>Producer</th>
          <th>Modules</th>
          <th>Count</th>
          <th>Electricity</th>
          <th>Pollution</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {entries.map(
          (
            {
              itemName,
              rate: desiredRate,
              recipeConfig: {
                recipe: { name: recipeName },
                producerConfig: {
                  producer: { name: producerName },
                  partialAmount: producerCount,
                  modules,
                  electricityUsage,
                  pollution,
                },
              },
            },
            index,
          ) => (
            <tr key={index}>
              <td>
                <ItemDisplay
                  itemName={itemName}
                  recipeName={recipeName}
                  breakLine
                />
              </td>
              <td>
                <Rate rate={desiredRate} />
              </td>
              <td>
                <NameDisplay name={producerName} />
              </td>
              <td>
                {modules.map((module, index) => (
                  <Icon name={module.name} key={index} />
                ))}
              </td>
              <td>{formatNumber(producerCount)}</td>
              <td>{formatMW(electricityUsage)}</td>
              <td>
                <Rate rate={pollution} />
              </td>
            </tr>
          ),
        )}
      </tbody>
      <tfoot className="table-group-divider">
        <tr>
          <th></th>
          <th>
            <Rate rate={desiredRateTotal} />
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th>{formatMW(electricityUsageTotal)}</th>
          <th>
            <Rate rate={pollutionTotal} />
          </th>
        </tr>
      </tfoot>
    </table>
  );
}
