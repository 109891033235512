import { ItemName } from "../data/item-names";
import { ModuleName } from "../data/modules";
import { ProducerName } from "../data/producers";
import { RecipeName } from "../data/recipes";
import { Requirement } from "../data/requirements";
import { Icon } from "./icon";

export interface NameDisplayProps {
  name: Requirement | ItemName | RecipeName | ProducerName | ModuleName;
  padLeft?: boolean;
}

export function NameDisplay({ name, padLeft }: NameDisplayProps): JSX.Element {
  return (
    <>
      <Icon name={name} padLeft={padLeft} padRight />
      {name}
    </>
  );
}
