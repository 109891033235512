import { ItemName } from "./item-names";
import { ProducerName, getProducer } from "./producers";
import { Requirement } from "./requirements";

export type RecipeName =
  | Exclude<
      ItemName,
      "Heavy oil" | "Light oil" | "Petroleum gas" | "Solid fuel"
    >
  | "Advanced oil processing"
  | "Basic oil processing"
  | "Coal liquefaction"
  | "Heavy oil cracking"
  | "Kovarex enrichment process"
  | "Light oil cracking"
  | "Nuclear fuel reprocessing"
  | "Solid fuel from heavy oil"
  | "Solid fuel from light oil"
  | "Solid fuel from petroleum gas"
  | "Uranium processing";

export interface Recipe {
  name: RecipeName;
  time: number;
  input?: Partial<Record<ItemName, number>>;
  output: Partial<Record<ItemName, number>>;
  requirements?: Requirement[];
  producers: ProducerName[];
}

const FLUID_ASSEMBLING_MACHINES: ProducerName[] = [
  "Assembling machine 2",
  "Assembling machine 3",
];

const ASSEMBLING_MACHINES: ProducerName[] = [
  "Assembling machine 1",
  ...FLUID_ASSEMBLING_MACHINES,
];

const FURNACES: ProducerName[] = [
  "Stone furnace",
  "Steel furnace",
  "Electric furnace",
];

const DRILLS: ProducerName[] = ["Burner mining drill", "Electric mining drill"];

const RECIPES: Recipe[] = [
  {
    name: "Accumulator",
    time: 10,
    input: { Battery: 5, "Iron plate": 2 },
    output: { Accumulator: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Active provider chest",
    time: 0.5,
    input: { "Advanced circuit": 1, "Electronic circuit": 3, "Steel chest": 1 },
    output: { "Active provider chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Advanced circuit",
    time: 6,
    input: { "Copper cable": 4, "Electronic circuit": 2, "Plastic bar": 2 },
    output: { "Advanced circuit": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Advanced oil processing",
    time: 5,
    input: { "Crude oil": 100, Water: 50 },
    output: { "Heavy oil": 25, "Light oil": 45, "Petroleum gas": 55 },
    requirements: ["Advanced oil processing"],
    producers: ["Oil refinery"],
  },
  {
    name: "Arithmetic combinator",
    time: 0.5,
    input: { "Copper cable": 5, "Electronic circuit": 5 },
    output: { "Arithmetic combinator": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Artillery shell",
    time: 15,
    input: { "Explosive cannon shell": 4, Explosives: 8, Radar: 1 },
    output: { "Artillery shell": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Artillery targeting remote",
    time: 0.5,
    input: { "Processing unit": 1, Radar: 1 },
    output: { "Artillery targeting remote": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Artillery turret",
    time: 40,
    input: {
      "Advanced circuit": 20,
      Concrete: 60,
      "Iron gear wheel": 40,
      "Steel plate": 60,
    },
    output: { "Artillery turret": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Artillery wagon",
    time: 4,
    input: {
      "Advanced circuit": 20,
      "Engine unit": 64,
      "Iron gear wheel": 40,
      Pipe: 16,
      "Steel plate": 40,
    },
    output: { "Artillery wagon": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Assembling machine 1",
    time: 0.5,
    input: { "Electronic circuit": 3, "Iron gear wheel": 5, "Iron plate": 9 },
    output: { "Assembling machine 1": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Assembling machine 2",
    time: 0.5,
    input: {
      "Assembling machine 1": 1,
      "Electronic circuit": 3,
      "Iron gear wheel": 5,
      "Steel plate": 2,
    },
    output: { "Assembling machine 2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Assembling machine 3",
    time: 0.5,
    input: { "Assembling machine 2": 2, "Speed module": 4 },
    output: { "Assembling machine 3": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Atomic bomb",
    time: 50,
    input: { Explosives: 10, "Rocket control unit": 10, "Uranium-235": 30 },
    output: { "Atomic bomb": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Automation science pack",
    time: 5,
    input: { "Copper plate": 1, "Iron gear wheel": 1 },
    output: { "Automation science pack": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Basic oil processing",
    time: 5,
    input: { "Crude oil": 100 },
    output: { "Petroleum gas": 45 },
    producers: ["Oil refinery"],
  },
  {
    name: "Battery",
    time: 4,
    input: { "Copper plate": 1, "Iron plate": 1, "Sulfuric acid": 20 },
    output: { Battery: 1 },
    producers: ["Chemical plant"],
  },
  {
    name: "Beacon",
    time: 15,
    input: {
      "Advanced circuit": 20,
      "Copper cable": 10,
      "Electronic circuit": 20,
      "Steel plate": 10,
    },
    output: { Beacon: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Belt immunity equipment",
    time: 10,
    input: { "Advanced circuit": 5, "Steel plate": 10 },
    output: { "Belt immunity equipment": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Big electric pole",
    time: 0.5,
    input: { "Copper plate": 5, "Iron stick": 8, "Steel plate": 5 },
    output: { "Big electric pole": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Boiler",
    time: 0.5,
    input: { Pipe: 4, "Stone furnace": 1 },
    output: { Boiler: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Buffer chest",
    time: 0.5,
    input: { "Advanced circuit": 1, "Electronic circuit": 3, "Steel chest": 1 },
    output: { "Buffer chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Burner inserter",
    time: 0.5,
    input: { "Iron gear wheel": 1, "Iron plate": 1 },
    output: { "Burner inserter": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Burner mining drill",
    time: 2,
    input: { "Iron gear wheel": 3, "Iron plate": 3, "Stone furnace": 1 },
    output: { "Burner mining drill": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Cannon shell",
    time: 8,
    input: { Explosives: 1, "Plastic bar": 2, "Steel plate": 2 },
    output: { "Cannon shell": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Car",
    time: 2,
    input: { "Engine unit": 8, "Iron plate": 20, "Steel plate": 5 },
    output: { Car: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Cargo wagon",
    time: 1,
    input: { "Iron gear wheel": 10, "Iron plate": 20, "Steel plate": 20 },
    output: { "Cargo wagon": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Centrifuge",
    time: 4,
    input: {
      "Advanced circuit": 100,
      Concrete: 100,
      "Iron gear wheel": 100,
      "Steel plate": 50,
    },
    output: { Centrifuge: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Chemical plant",
    time: 5,
    input: {
      "Electronic circuit": 5,
      "Iron gear wheel": 5,
      Pipe: 5,
      "Steel plate": 5,
    },
    output: { "Chemical plant": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Chemical science pack",
    time: 24,
    input: { "Advanced circuit": 3, "Engine unit": 2, Sulfur: 1 },
    output: { "Chemical science pack": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Cliff explosives",
    time: 8,
    input: { "Empty barrel": 1, Explosives: 10, Grenade: 1 },
    output: { "Cliff explosives": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Cluster grenade",
    time: 8,
    input: { Explosives: 5, Grenade: 7, "Steel plate": 5 },
    output: { "Cluster grenade": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Coal",
    time: 1,
    output: { Coal: 1 },
    producers: DRILLS,
  },
  {
    name: "Coal liquefaction",
    time: 5,
    // Coal liquefaction requires 25 Heavy oil and and yields 90 Heavy oil. This
    // recipe simplifies this to its net output of 65 Heavy oil.
    input: { Coal: 10, Steam: 50 },
    output: { "Heavy oil": 65, "Light oil": 20, "Petroleum gas": 10 },
    requirements: ["Coal liquefaction"],
    producers: ["Oil refinery"],
  },
  {
    name: "Combat shotgun",
    time: 10,
    input: {
      "Copper plate": 10,
      "Iron gear wheel": 5,
      "Steel plate": 15,
      Wood: 10,
    },
    output: { "Combat shotgun": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Concrete",
    time: 10,
    input: { "Iron ore": 1, "Stone brick": 5, Water: 100 },
    output: { Concrete: 10 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Constant combinator",
    time: 0.5,
    input: { "Copper cable": 5, "Electronic circuit": 2 },
    output: { "Constant combinator": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Copper cable",
    time: 0.5,
    input: { "Copper plate": 1 },
    output: { "Copper cable": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Construction robot",
    time: 0.5,
    input: { "Electronic circuit": 2, "Flying robot frame": 1 },
    output: { "Construction robot": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Copper ore",
    time: 1,
    output: { "Copper ore": 1 },
    producers: DRILLS,
  },
  {
    name: "Copper plate",
    time: 3.2,
    input: { "Copper ore": 1 },
    output: { "Copper plate": 1 },
    producers: FURNACES,
  },
  {
    name: "Crude oil",
    time: 1,
    output: { "Crude oil": 10 },
    producers: ["Pumpjack"],
  },
  {
    name: "Decider combinator",
    time: 0.5,
    input: { "Copper cable": 5, "Electronic circuit": 5 },
    output: { "Decider combinator": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Defender capsule",
    time: 8,
    input: {
      "Electronic circuit": 3,
      "Iron gear wheel": 3,
      "Piercing rounds magazine": 3,
    },
    output: { "Defender capsule": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Destroyer capsule",
    time: 15,
    input: { "Distractor capsule": 4, "Speed module": 1 },
    output: { "Destroyer capsule": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Discharge defense",
    time: 10,
    input: { "Laser turret": 10, "Processing unit": 5, "Steel plate": 20 },
    output: { "Discharge defense": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Discharge defense remote",
    time: 0.5,
    input: { "Electronic circuit": 1 },
    output: { "Discharge defense remote": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Distractor capsule",
    time: 15,
    input: { "Advanced circuit": 3, "Defender capsule": 4 },
    output: { "Distractor capsule": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Efficiency module",
    time: 15,
    input: { "Advanced circuit": 5, "Electronic circuit": 5 },
    output: { "Efficiency module": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Efficiency module 2",
    time: 30,
    input: {
      "Advanced circuit": 5,
      "Efficiency module": 4,
      "Processing unit": 5,
    },
    output: { "Efficiency module 2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Efficiency module 3",
    time: 60,
    input: {
      "Advanced circuit": 5,
      "Efficiency module 2": 5,
      "Processing unit": 5,
    },
    output: { "Efficiency module 3": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Electric engine unit",
    time: 10,
    input: { "Electronic circuit": 2, "Engine unit": 1, Lubricant: 15 },
    output: { "Electric engine unit": 1 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Electric furnace",
    time: 5,
    input: { "Advanced circuit": 5, "Steel plate": 10, "Stone brick": 10 },
    output: { "Electric furnace": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Electric mining drill",
    time: 2,
    input: { "Electronic circuit": 3, "Iron gear wheel": 5, "Iron plate": 10 },
    output: { "Electric mining drill": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Electronic circuit",
    time: 0.5,
    input: { "Copper cable": 3, "Iron plate": 1 },
    output: { "Electronic circuit": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Empty barrel",
    time: 1,
    input: { "Steel plate": 1 },
    output: { "Empty barrel": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Energy shield",
    time: 10,
    input: { "Advanced circuit": 5, "Steel plate": 10 },
    output: { "Energy shield": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Energy shield MK2",
    time: 10,
    input: {
      "Energy shield": 10,
      "Low density structure": 5,
      "Processing unit": 5,
    },
    output: { "Energy shield MK2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Engine unit",
    time: 10,
    input: { "Iron gear wheel": 1, Pipe: 2, "Steel plate": 1 },
    output: { "Engine unit": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Exoskeleton",
    time: 10,
    input: {
      "Electric engine unit": 30,
      "Processing unit": 10,
      "Steel plate": 20,
    },
    output: { Exoskeleton: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Explosive cannon shell",
    time: 8,
    input: { Explosives: 2, "Plastic bar": 2, "Steel plate": 2 },
    output: { "Explosive cannon shell": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Explosive rocket",
    time: 8,
    input: { Explosives: 2, Rocket: 1 },
    output: { "Explosive rocket": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Explosive uranium cannon shell",
    time: 12,
    input: { "Explosive cannon shell": 1, "Uranium-238": 1 },
    output: { "Explosive uranium cannon shell": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Explosives",
    time: 4,
    input: { Coal: 1, Sulfur: 1, Water: 10 },
    output: { Explosives: 2 },
    producers: ["Chemical plant"],
  },
  {
    name: "Express splitter",
    time: 2,
    input: {
      "Advanced circuit": 10,
      "Fast splitter": 1,
      "Iron gear wheel": 10,
      Lubricant: 80,
    },
    output: { "Express splitter": 1 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Express transport belt",
    time: 0.5,
    input: { "Fast transport belt": 1, "Iron gear wheel": 10, Lubricant: 20 },
    output: { "Express transport belt": 1 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Express underground belt",
    time: 2,
    input: { "Fast underground belt": 2, "Iron gear wheel": 80, Lubricant: 40 },
    output: { "Express underground belt": 2 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Fast inserter",
    time: 0.5,
    input: { "Electronic circuit": 2, Inserter: 1, "Iron plate": 2 },
    output: { "Fast inserter": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Fast splitter",
    time: 2,
    input: { "Electronic circuit": 10, "Iron gear wheel": 10, Splitter: 1 },
    output: { "Fast splitter": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Fast transport belt",
    time: 0.5,
    input: { "Iron gear wheel": 5, "Transport belt": 1 },
    output: { "Fast transport belt": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Fast underground belt",
    time: 2,
    input: { "Iron gear wheel": 40, "Underground belt": 2 },
    output: { "Fast underground belt": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Filter inserter",
    time: 0.5,
    input: { "Electronic circuit": 4, "Fast inserter": 1 },
    output: { "Filter inserter": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Firearm magazine",
    time: 1,
    input: { "Iron plate": 4 },
    output: { "Firearm magazine": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Flamethrower",
    time: 10,
    input: { "Iron gear wheel": 10, "Steel plate": 5 },
    output: { Flamethrower: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Flamethrower ammo",
    time: 6,
    input: { "Crude oil": 100, "Steel plate": 5 },
    output: { "Flamethrower ammo": 1 },
    producers: ["Chemical plant"],
  },
  {
    name: "Flamethrower turret",
    time: 20,
    input: {
      "Engine unit": 5,
      "Iron gear wheel": 15,
      Pipe: 10,
      "Steel plate": 30,
    },
    output: { "Flamethrower turret": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Fluid wagon",
    time: 1.5,
    input: {
      "Iron gear wheel": 10,
      Pipe: 8,
      "Steel plate": 16,
      "Storage tank": 1,
    },
    output: { "Fluid wagon": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Flying robot frame",
    time: 20,
    input: {
      Battery: 2,
      "Electric engine unit": 1,
      "Electronic circuit": 3,
      "Steel plate": 1,
    },
    output: { "Flying robot frame": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Gate",
    time: 0.5,
    input: { "Electronic circuit": 2, "Steel plate": 2, Wall: 1 },
    output: { Gate: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Green wire",
    time: 0.5,
    input: { "Copper cable": 1, "Electronic circuit": 1 },
    output: { "Green wire": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Grenade",
    time: 8,
    input: { Coal: 10, "Iron plate": 5 },
    output: { Grenade: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Gun turret",
    time: 8,
    input: { "Copper plate": 10, "Iron gear wheel": 10, "Iron plate": 20 },
    output: { "Gun turret": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Hazard concrete",
    time: 0.25,
    input: { Concrete: 10 },
    output: { "Hazard concrete": 10 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Heat exchanger",
    time: 3,
    input: { "Copper plate": 100, Pipe: 10, "Steel plate": 10 },
    output: { "Heat exchanger": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Heat pipe",
    time: 1,
    input: { "Copper plate": 20, "Steel plate": 10 },
    output: { "Heat pipe": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Heavy armor",
    time: 8,
    input: { "Copper plate": 100, "Steel plate": 50 },
    output: { "Heavy armor": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Heavy oil cracking",
    time: 2,
    input: { "Heavy oil": 40, Water: 30 },
    output: { "Light oil": 30 },
    requirements: ["Heavy oil cracking"],
    producers: ["Chemical plant"],
  },
  {
    name: "Iron gear wheel",
    time: 0.5,
    input: { "Iron plate": 2 },
    output: { "Iron gear wheel": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Inserter",
    time: 0.5,
    input: { "Electronic circuit": 1, "Iron gear wheel": 1, "Iron plate": 1 },
    output: { Inserter: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Iron chest",
    time: 0.5,
    input: { "Iron plate": 8 },
    output: { "Iron chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Iron ore",
    time: 1,
    output: { "Iron ore": 1 },
    producers: DRILLS,
  },
  {
    name: "Iron plate",
    time: 3.2,
    input: { "Iron ore": 1 },
    output: { "Iron plate": 1 },
    producers: FURNACES,
  },
  {
    name: "Iron stick",
    time: 0.5,
    input: { "Iron plate": 1 },
    output: { "Iron stick": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Kovarex enrichment process",
    time: 60,
    // Kovarex enrichment process requires 40 U-235 and 5 U-238 and yields 41
    // U-235 and 2 U-238. This recipe simplifies this to one input item and one
    // output item.
    input: { "Uranium-238": 3 },
    output: { "Uranium-235": 1 },
    requirements: ["Kovarex enrichment process"],
    producers: ["Centrifuge"],
  },
  {
    name: "Lab",
    time: 2,
    input: {
      "Electronic circuit": 10,
      "Iron gear wheel": 10,
      "Transport belt": 4,
    },
    output: { Lab: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Lamp",
    time: 0.5,
    input: { "Copper cable": 3, "Electronic circuit": 1, "Iron plate": 1 },
    output: { Lamp: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Land mine",
    time: 5,
    input: { Explosives: 2, "Steel plate": 1 },
    output: { "Land mine": 4 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Landfill",
    time: 0.5,
    input: { Stone: 20 },
    output: { Landfill: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Laser turret",
    time: 20,
    input: { Battery: 12, "Electronic circuit": 20, "Steel plate": 20 },
    output: { "Laser turret": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Light armor",
    time: 3,
    input: { "Iron plate": 40 },
    output: { "Light armor": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Light oil cracking",
    time: 2,
    input: { "Light oil": 30, Water: 30 },
    output: { "Petroleum gas": 20 },
    requirements: ["Light oil cracking"],
    producers: ["Chemical plant"],
  },
  {
    name: "Locomotive",
    time: 4,
    input: { "Electronic circuit": 10, "Engine unit": 20, "Steel plate": 30 },
    output: { Locomotive: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Logistic robot",
    time: 0.5,
    input: { "Advanced circuit": 2, "Flying robot frame": 1 },
    output: { "Logistic robot": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Logistic science pack",
    time: 6,
    input: { Inserter: 1, "Transport belt": 1 },
    output: { "Logistic science pack": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Long-handed inserter",
    time: 0.5,
    input: { Inserter: 1, "Iron gear wheel": 1, "Iron plate": 1 },
    output: { "Long-handed inserter": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Low density structure",
    time: 20,
    input: { "Copper plate": 20, "Plastic bar": 5, "Steel plate": 2 },
    output: { "Low density structure": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Lubricant",
    time: 1,
    input: { "Heavy oil": 10 },
    output: { Lubricant: 10 },
    producers: ["Chemical plant"],
  },
  {
    name: "Medium electric pole",
    time: 0.5,
    input: { "Copper plate": 2, "Iron stick": 4, "Steel plate": 2 },
    output: { "Medium electric pole": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Military science pack",
    time: 10,
    input: { Grenade: 1, "Piercing rounds magazine": 1, Wall: 1 },
    output: { "Military science pack": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Modular armor",
    time: 15,
    input: { "Advanced circuit": 30, "Steel plate": 50 },
    output: { "Modular armor": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Nightvision",
    time: 10,
    input: { "Advanced circuit": 5, "Steel plate": 10 },
    output: { Nightvision: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Nuclear fuel",
    time: 90,
    input: { "Rocket fuel": 1, "Uranium-235": 1 },
    output: { "Nuclear fuel": 1 },
    producers: ["Centrifuge"],
  },
  {
    name: "Nuclear fuel reprocessing",
    time: 60,
    input: { "Used up uranium fuel cell": 5 },
    output: { "Uranium-238": 3 },
    producers: ["Centrifuge"],
  },
  {
    name: "Nuclear reactor",
    time: 8,
    input: {
      "Advanced circuit": 500,
      Concrete: 500,
      "Copper plate": 500,
      "Steel plate": 500,
    },
    output: { "Nuclear reactor": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Offshore pump",
    time: 0.5,
    input: { "Electronic circuit": 2, "Iron gear wheel": 1, Pipe: 1 },
    output: { "Offshore pump": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Oil refinery",
    time: 8,
    input: {
      "Electronic circuit": 10,
      "Iron gear wheel": 10,
      Pipe: 10,
      "Steel plate": 15,
      Concrete: 10,
    },
    output: { "Oil refinery": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Passive provider chest",
    time: 0.5,
    input: { "Advanced circuit": 1, "Electronic circuit": 3, "Steel chest": 1 },
    output: { "Passive provider chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Personal battery",
    time: 10,
    input: { Battery: 5, "Steel plate": 10 },
    output: { "Personal battery": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Personal battery MK2",
    time: 10,
    input: {
      "Personal battery": 10,
      "Low density structure": 5,
      "Processing unit": 15,
    },
    output: { "Personal battery MK2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Personal laser defense",
    time: 10,
    input: {
      "Laser turret": 5,
      "Low density structure": 5,
      "Processing unit": 20,
    },
    output: { "Personal laser defense": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Personal roboport",
    time: 10,
    input: {
      "Advanced circuit": 10,
      Battery: 45,
      "Iron gear wheel": 40,
      "Steel plate": 20,
    },
    output: { "Personal roboport": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Personal roboport MK2",
    time: 20,
    input: {
      "Low density structure": 20,
      "Personal roboport": 5,
      "Processing unit": 100,
    },
    output: { "Personal roboport MK2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Piercing rounds magazine",
    time: 3,
    input: { "Copper plate": 5, "Firearm magazine": 1, "Steel plate": 1 },
    output: { "Piercing rounds magazine": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Piercing shotgun shells",
    time: 8,
    input: { "Copper plate": 5, "Shotgun shells": 2, "Steel plate": 2 },
    output: { "Piercing shotgun shells": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Pipe",
    time: 0.5,
    input: { "Iron plate": 1 },
    output: { Pipe: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Pipe to ground",
    time: 0.5,
    input: { "Iron plate": 5, Pipe: 10 },
    output: { "Pipe to ground": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Pistol",
    time: 5,
    input: { "Copper plate": 5, "Iron plate": 5 },
    output: { Pistol: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Plastic bar",
    time: 1,
    input: { Coal: 1, "Petroleum gas": 20 },
    output: { "Plastic bar": 2 },
    producers: ["Chemical plant"],
  },
  {
    name: "Poison capsule",
    time: 8,
    input: { Coal: 10, "Electronic circuit": 3, "Steel plate": 3 },
    output: { "Poison capsule": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Portable fusion reactor",
    time: 10,
    input: { "Low density structure": 50, "Processing unit": 200 },
    output: { "Portable fusion reactor": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Portable solar panel",
    time: 10,
    input: { "Advanced circuit": 2, "Solar panel": 1, "Steel plate": 5 },
    output: { "Portable solar panel": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Power armor",
    time: 20,
    input: {
      "Electric engine unit": 20,
      "Processing unit": 40,
      "Steel plate": 40,
    },
    output: { "Power armor": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Power armor MK2",
    time: 25,
    input: {
      "Efficiency module 2": 25,
      "Electric engine unit": 40,
      "Low density structure": 30,
      "Processing unit": 60,
      "Speed module 2": 25,
    },
    output: { "Power armor MK2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Power switch",
    time: 2,
    input: { "Copper cable": 5, "Electronic circuit": 2, "Iron plate": 5 },
    output: { "Power switch": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Processing unit",
    time: 10,
    input: {
      "Advanced circuit": 2,
      "Electronic circuit": 20,
      "Sulfuric acid": 5,
    },
    output: { "Processing unit": 1 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Production science pack",
    time: 21,
    input: { "Electric furnace": 1, "Productivity module": 1, Rail: 30 },
    output: { "Production science pack": 3 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Productivity module",
    time: 15,
    input: { "Advanced circuit": 5, "Electronic circuit": 5 },
    output: { "Productivity module": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Productivity module 2",
    time: 30,
    input: {
      "Advanced circuit": 5,
      "Processing unit": 5,
      "Productivity module": 4,
    },
    output: { "Productivity module 2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Productivity module 3",
    time: 60,
    input: {
      "Advanced circuit": 5,
      "Processing unit": 5,
      "Productivity module 2": 5,
    },
    output: { "Productivity module 3": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Programmable speaker",
    time: 2,
    input: {
      "Copper cable": 5,
      "Electronic circuit": 4,
      "Iron plate": 3,
      "Iron stick": 4,
    },
    output: { "Programmable speaker": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Pump",
    time: 2,
    input: { "Engine unit": 1, Pipe: 1, "Steel plate": 1 },
    output: { Pump: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Pumpjack",
    time: 5,
    input: {
      "Electronic circuit": 5,
      "Iron gear wheel": 10,
      Pipe: 10,
      "Steel plate": 5,
    },
    output: { Pumpjack: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Radar",
    time: 0.5,
    input: { "Advanced circuit": 5, "Iron gear wheel": 5, "Iron plate": 10 },
    output: { Radar: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Rail",
    time: 0.5,
    input: { "Iron stick": 1, "Steel plate": 1, Stone: 1 },
    output: { Rail: 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Rail chain signal",
    time: 0.5,
    input: { "Electronic circuit": 1, "Iron plate": 5 },
    output: { "Rail chain signal": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Rail signal",
    time: 0.5,
    input: { "Electronic circuit": 1, "Iron plate": 5 },
    output: { "Rail signal": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Raw fish",
    time: 0.4,
    output: { "Raw fish": 5 },
    producers: ["Player"],
  },
  {
    name: "Red wire",
    time: 0.5,
    input: { "Copper cable": 1, "Electronic circuit": 1 },
    output: { "Red wire": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Refined concrete",
    time: 15,
    input: { Concrete: 20, "Iron stick": 8, "Steel plate": 1, Water: 100 },
    output: { "Refined concrete": 10 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Refined hazard concrete",
    time: 0.25,
    input: { "Refined concrete": 10 },
    output: { "Refined hazard concrete": 10 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Repair pack",
    time: 0.5,
    input: { "Electronic circuit": 2, "Iron gear wheel": 2 },
    output: { "Repair pack": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Requester chest",
    time: 0.5,
    input: { "Advanced circuit": 1, "Electronic circuit": 3, "Steel chest": 1 },
    output: { "Requester chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Roboport",
    time: 5,
    input: { "Advanced circuit": 45, "Iron gear wheel": 45, "Steel plate": 45 },
    output: { Roboport: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Rocket",
    time: 8,
    input: { "Electronic circuit": 1, Explosives: 1, "Iron plate": 2 },
    output: { Rocket: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Rocket control unit",
    time: 30,
    input: { "Processing unit": 1, "Speed module": 1 },
    output: { "Rocket control unit": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Rocket fuel",
    time: 30,
    input: { "Light oil": 10, "Solid fuel": 10 },
    output: { "Rocket fuel": 1 },
    producers: FLUID_ASSEMBLING_MACHINES,
  },
  {
    name: "Rocket launcher",
    time: 10,
    input: { "Electronic circuit": 5, "Iron gear wheel": 5, "Iron plate": 5 },
    output: { "Rocket launcher": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Rocket part",
    time: 3,
    input: {
      "Low density structure": 10,
      "Rocket control unit": 10,
      "Rocket fuel": 10,
    },
    output: { "Rocket part": 1 },
    producers: ["Rocket silo"],
  },
  {
    name: "Rocket silo",
    time: 30,
    input: {
      Concrete: 1000,
      "Electric engine unit": 200,
      Pipe: 100,
      "Processing unit": 200,
      "Steel plate": 1000,
    },
    output: { "Rocket silo": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Satellite",
    time: 5,
    input: {
      Accumulator: 100,
      "Low density structure": 100,
      "Processing unit": 100,
      Radar: 5,
      "Rocket fuel": 50,
      "Solar panel": 100,
    },
    output: { Satellite: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Shotgun",
    time: 10,
    input: {
      "Copper plate": 10,
      "Iron gear wheel": 5,
      "Iron plate": 15,
      Wood: 5,
    },
    output: { Shotgun: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Shotgun shells",
    time: 3,
    input: { "Copper plate": 2, "Iron plate": 2 },
    output: { "Shotgun shells": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Slowdown capsule",
    time: 8,
    input: { Coal: 5, "Electronic circuit": 2, "Steel plate": 2 },
    output: { "Slowdown capsule": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Small electric pole",
    time: 0.5,
    input: { "Copper cable": 2, Wood: 1 },
    output: { "Small electric pole": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Solar panel",
    time: 10,
    input: { "Copper plate": 5, "Electronic circuit": 15, "Steel plate": 5 },
    output: { "Solar panel": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Solid fuel from heavy oil",
    time: 2,
    input: { "Heavy oil": 20 },
    output: { "Solid fuel": 1 },
    requirements: ["Solid fuel from heavy oil"],
    producers: ["Chemical plant"],
  },
  {
    name: "Solid fuel from light oil",
    time: 2,
    input: { "Light oil": 10 },
    output: { "Solid fuel": 1 },
    requirements: ["Solid fuel from light oil"],
    producers: ["Chemical plant"],
  },
  {
    name: "Solid fuel from petroleum gas",
    time: 2,
    input: { "Petroleum gas": 20 },
    output: { "Solid fuel": 1 },
    requirements: ["Solid fuel from petroleum gas"],
    producers: ["Chemical plant"],
  },
  {
    name: "Space science pack",
    time: 1,
    input: { "Rocket part": 100, Satellite: 1 },
    output: { "Space science pack": 1000 },
    producers: ["Rocket silo"],
  },
  {
    name: "Speed module",
    time: 15,
    input: { "Advanced circuit": 5, "Electronic circuit": 5 },
    output: { "Speed module": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Speed module 2",
    time: 30,
    input: { "Advanced circuit": 5, "Processing unit": 5, "Speed module": 4 },
    output: { "Speed module 2": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Speed module 3",
    time: 60,
    input: { "Advanced circuit": 5, "Processing unit": 5, "Speed module 2": 5 },
    output: { "Speed module 3": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Spidertron",
    time: 10,
    input: {
      "Efficiency module 3": 2,
      Exoskeleton: 4,
      "Portable fusion reactor": 2,
      "Low density structure": 150,
      Radar: 2,
      "Raw fish": 1,
      "Rocket control unit": 16,
      "Rocket launcher": 4,
    },
    output: { Spidertron: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Spidertron remote",
    time: 0.5,
    input: { Radar: 1, "Rocket control unit": 1 },
    output: { "Spidertron remote": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Splitter",
    time: 1,
    input: { "Electronic circuit": 5, "Iron plate": 5, "Transport belt": 4 },
    output: { Splitter: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Stack filter inserter",
    time: 0.5,
    input: { "Electronic circuit": 5, "Stack inserter": 1 },
    output: { "Stack filter inserter": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Stack inserter",
    time: 0.5,
    input: {
      "Advanced circuit": 1,
      "Electronic circuit": 15,
      "Fast inserter": 1,
    },
    output: { "Stack inserter": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Steam",
    time: 1,
    input: { Water: 60 },
    output: { Steam: 60 },
    producers: ["Boiler", "Heat exchanger"],
  },
  {
    name: "Steam engine",
    time: 0.5,
    input: { "Iron gear wheel": 8, "Iron plate": 10, Pipe: 5 },
    output: { "Steam engine": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Steam turbine",
    time: 3,
    input: { "Copper plate": 50, "Iron gear wheel": 50, Pipe: 20 },
    output: { "Steam turbine": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Steel chest",
    time: 0.5,
    input: { "Steel plate": 8 },
    output: { "Steel chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Steel furnace",
    time: 3,
    input: { "Steel plate": 6, "Stone brick": 10 },
    output: { "Steel furnace": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Steel plate",
    time: 16,
    input: { "Iron plate": 5 },
    output: { "Steel plate": 1 },
    producers: FURNACES,
  },
  {
    name: "Stone",
    time: 1,
    output: { Stone: 1 },
    producers: DRILLS,
  },
  {
    name: "Stone brick",
    time: 3.2,
    input: { Stone: 2 },
    output: { "Stone brick": 1 },
    producers: FURNACES,
  },
  {
    name: "Stone furnace",
    time: 0.5,
    input: { Stone: 5 },
    output: { "Stone furnace": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Storage chest",
    time: 0.5,
    input: { "Advanced circuit": 1, "Electronic circuit": 5, "Steel chest": 1 },
    output: { "Storage chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Storage tank",
    time: 3,
    input: { "Iron gear wheel": 20, "Steel plate": 5 },
    output: { "Storage tank": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Submachine gun",
    time: 10,
    input: { "Copper plate": 5, "Iron gear wheel": 10, "Iron plate": 10 },
    output: { "Submachine gun": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Substation",
    time: 0.5,
    input: { "Advanced circuit": 5, "Copper plate": 5, "Steel plate": 10 },
    output: { Substation: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Sulfur",
    time: 1,
    input: { "Petroleum gas": 30, Water: 30 },
    output: { Sulfur: 2 },
    producers: ["Chemical plant"],
  },
  {
    name: "Sulfuric acid",
    time: 1,
    input: { Sulfur: 5, Water: 100 },
    output: { "Sulfuric acid": 50 },
    producers: ["Chemical plant"],
  },
  {
    name: "Tank",
    time: 5,
    input: {
      "Advanced circuit": 10,
      "Engine unit": 32,
      "Iron gear wheel": 15,
      "Steel plate": 50,
    },
    output: { Tank: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Transport belt",
    time: 0.5,
    input: { "Iron gear wheel": 1, "Iron plate": 1 },
    output: { "Transport belt": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Underground belt",
    time: 1,
    input: { "Iron plate": 10, "Transport belt": 5 },
    output: { "Underground belt": 2 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Uranium cannon shell",
    time: 12,
    input: { "Cannon shell": 1, "Uranium-238": 1 },
    output: { "Uranium cannon shell": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Uranium fuel cell",
    time: 10,
    input: { "Iron plate": 10, "Uranium-235": 1, "Uranium-238": 19 },
    output: { "Uranium fuel cell": 10 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Uranium ore",
    time: 2,
    input: { "Sulfuric acid": 1 },
    output: { "Uranium ore": 1 },
    producers: ["Electric mining drill"],
  },
  {
    name: "Uranium processing",
    time: 12,
    input: { "Uranium ore": 10 },
    output: { "Uranium-235": 0.007, "Uranium-238": 0.993 },
    producers: ["Centrifuge"],
  },
  {
    name: "Uranium rounds magazine",
    time: 10,
    input: { "Piercing rounds magazine": 1, "Uranium-238": 1 },
    output: { "Uranium rounds magazine": 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Used up uranium fuel cell",
    time: 200,
    input: { "Uranium fuel cell": 1 },
    output: { "Used up uranium fuel cell": 1 },
    producers: ["Nuclear reactor"],
  },
  {
    name: "Utility science pack",
    time: 21,
    input: {
      "Flying robot frame": 1,
      "Low density structure": 3,
      "Processing unit": 2,
    },
    output: { "Utility science pack": 3 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Wall",
    time: 0.5,
    input: { "Stone brick": 5 },
    output: { Wall: 1 },
    producers: ASSEMBLING_MACHINES,
  },
  {
    name: "Water",
    time: 1,
    output: { Water: 1200 },
    producers: ["Offshore pump"],
  },
  {
    name: "Wood",
    time: 0.5,
    output: { Wood: 1 },
    producers: ["Player"],
  },
  {
    name: "Wooden chest",
    time: 0.5,
    input: { Wood: 2 },
    output: { "Wooden chest": 1 },
    producers: ASSEMBLING_MACHINES,
  },
];

export function getRecipes(): Recipe[] {
  return RECIPES.slice();
}

export function getRecipe(recipeName: RecipeName): Recipe {
  return RECIPES.find(({ name }) => name === recipeName)!;
}

export function getRecipesByOutput(
  itemName: ItemName,
  requirementsMet?: Requirement[],
): Recipe[] {
  const recipes = RECIPES.filter(({ output }) =>
    Object.keys(output).includes(itemName),
  );

  return requirementsMet == null
    ? recipes
    : recipes.filter(
        ({ requirements }) =>
          requirements == null ||
          requirements.every((requirement) =>
            requirementsMet.includes(requirement),
          ),
      );
}

export function getMissingRequirements(
  itemName: ItemName,
  requirementsMet: Requirement[],
): Requirement[] {
  const recipes = getRecipesByOutput(itemName);
  return recipes
    .flatMap(({ requirements }) => requirements ?? [])
    .concat(
      recipes
        .flatMap(({ producers }) => producers)
        .map(getProducer)
        .flatMap(({ requirements }) => requirements ?? []),
    )
    .filter((requirement) => !requirementsMet.includes(requirement))
    .reduce(
      (requirements, requirement) =>
        requirements.includes(requirement)
          ? requirements
          : [...requirements, requirement],
      [] as Requirement[],
    );
}
