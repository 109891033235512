export type Requirement =
  | "Electric mining drill"
  | "Automation"
  | "Automation 2"
  | "Automation 3"
  | "Advanced material processing"
  | "Advanced material processing 2"
  | "Advanced oil processing"
  | "Heavy oil cracking"
  | "Light oil cracking"
  | "Coal liquefaction"
  | "Solid fuel from heavy oil"
  | "Solid fuel from light oil"
  | "Solid fuel from petroleum gas"
  | "Kovarex enrichment process"
  | "Heat exchanger"
  | "Efficiency module"
  | "Efficiency module 2"
  | "Efficiency module 3"
  | "Productivity module"
  | "Productivity module 2"
  | "Productivity module 3"
  | "Speed module"
  | "Speed module 2"
  | "Speed module 3";

const REQUIREMENTS: Requirement[] = [
  "Electric mining drill",
  "Automation",
  "Automation 2",
  "Automation 3",
  "Advanced material processing",
  "Advanced material processing 2",
  "Advanced oil processing",
  "Heavy oil cracking",
  "Light oil cracking",
  "Coal liquefaction",
  "Solid fuel from heavy oil",
  "Solid fuel from light oil",
  "Solid fuel from petroleum gas",
  "Kovarex enrichment process",
  "Heat exchanger",
  "Efficiency module",
  "Efficiency module 2",
  "Efficiency module 3",
  "Productivity module",
  "Productivity module 2",
  "Productivity module 3",
  "Speed module",
  "Speed module 2",
  "Speed module 3",
];

export function getRequirements(): Requirement[] {
  return REQUIREMENTS.slice();
}
