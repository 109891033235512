import { Requirement } from "./requirements";

export type ModuleName =
  | "Efficiency module"
  | "Efficiency module 2"
  | "Efficiency module 3"
  | "Productivity module"
  | "Productivity module 2"
  | "Productivity module 3"
  | "Speed module"
  | "Speed module 2"
  | "Speed module 3";

export interface Module {
  name: ModuleName;
  requirements: Requirement[];
  engeryModifier?: number;
  pollutionModifier?: number;
  productionModifier?: number;
  speedModifier?: number;
}

const MODULES: Module[] = [
  {
    name: "Efficiency module",
    requirements: ["Efficiency module"],
    engeryModifier: -0.3,
  },
  {
    name: "Efficiency module 2",
    requirements: ["Efficiency module 2"],
    engeryModifier: -0.4,
  },
  {
    name: "Efficiency module 3",
    requirements: ["Efficiency module 3"],
    engeryModifier: -0.5,
  },
  {
    name: "Productivity module",
    requirements: ["Productivity module"],
    engeryModifier: 0.4,
    pollutionModifier: 0.05,
    productionModifier: 0.04,
    speedModifier: -0.05,
  },
  {
    name: "Productivity module 2",
    requirements: ["Productivity module 2"],
    engeryModifier: 0.6,
    pollutionModifier: 0.07,
    productionModifier: 0.06,
    speedModifier: -0.1,
  },
  {
    name: "Productivity module 3",
    requirements: ["Productivity module 3"],
    engeryModifier: 0.8,
    pollutionModifier: 0.1,
    productionModifier: 0.1,
    speedModifier: -0.15,
  },
  {
    name: "Speed module",
    requirements: ["Speed module"],
    engeryModifier: 0.5,
    speedModifier: 0.2,
  },
  {
    name: "Speed module 2",
    requirements: ["Speed module 2"],
    engeryModifier: 0.6,
    speedModifier: 0.3,
  },
  {
    name: "Speed module 3",
    requirements: ["Speed module 3"],
    engeryModifier: 0.7,
    speedModifier: 0.5,
  },
];

export function getModules(): Module[] {
  return MODULES.slice();
}

export function getModule(moduleName: ModuleName): Module {
  return MODULES.find(({ name }) => name === moduleName)!;
}
