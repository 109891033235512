export type ItemName =
  | "Wooden chest"
  | "Iron chest"
  | "Steel chest"
  | "Storage tank"
  | "Transport belt"
  | "Fast transport belt"
  | "Express transport belt"
  | "Underground belt"
  | "Fast underground belt"
  | "Express underground belt"
  | "Splitter"
  | "Fast splitter"
  | "Express splitter"
  | "Burner inserter"
  | "Inserter"
  | "Long-handed inserter"
  | "Fast inserter"
  | "Filter inserter"
  | "Stack inserter"
  | "Stack filter inserter"
  | "Small electric pole"
  | "Medium electric pole"
  | "Big electric pole"
  | "Substation"
  | "Pipe"
  | "Pipe to ground"
  | "Pump"
  | "Rail"
  | "Rail signal"
  | "Rail chain signal"
  | "Locomotive"
  | "Cargo wagon"
  | "Fluid wagon"
  | "Artillery wagon"
  | "Car"
  | "Tank"
  | "Spidertron"
  | "Spidertron remote"
  | "Logistic robot"
  | "Construction robot"
  | "Active provider chest"
  | "Passive provider chest"
  | "Storage chest"
  | "Buffer chest"
  | "Requester chest"
  | "Roboport"
  | "Lamp"
  | "Red wire"
  | "Green wire"
  | "Arithmetic combinator"
  | "Decider combinator"
  | "Constant combinator"
  | "Power switch"
  | "Programmable speaker"
  | "Stone brick"
  | "Concrete"
  | "Hazard concrete"
  | "Refined concrete"
  | "Refined hazard concrete"
  | "Landfill"
  | "Cliff explosives"
  | "Repair pack"
  | "Blueprint"
  | "Deconstruction planner"
  | "Upgrade planner"
  | "Blueprint book"
  | "Boiler"
  | "Steam engine"
  | "Solar panel"
  | "Accumulator"
  | "Nuclear reactor"
  | "Heat pipe"
  | "Heat exchanger"
  | "Steam turbine"
  | "Burner mining drill"
  | "Electric mining drill"
  | "Offshore pump"
  | "Pumpjack"
  | "Stone furnace"
  | "Steel furnace"
  | "Electric furnace"
  | "Assembling machine 1"
  | "Assembling machine 2"
  | "Assembling machine 3"
  | "Oil refinery"
  | "Chemical plant"
  | "Centrifuge"
  | "Lab"
  | "Beacon"
  | "Speed module"
  | "Speed module 2"
  | "Speed module 3"
  | "Efficiency module"
  | "Efficiency module 2"
  | "Efficiency module 3"
  | "Productivity module"
  | "Productivity module 2"
  | "Productivity module 3"
  | "Rocket silo"
  | "Satellite"
  | "Crude oil"
  | "Heavy oil"
  | "Light oil"
  | "Lubricant"
  | "Petroleum gas"
  | "Sulfuric acid"
  | "Water"
  | "Steam"
  | "Wood"
  | "Coal"
  | "Stone"
  | "Iron ore"
  | "Copper ore"
  | "Uranium ore"
  | "Raw fish"
  | "Iron plate"
  | "Copper plate"
  | "Solid fuel"
  | "Steel plate"
  | "Plastic bar"
  | "Sulfur"
  | "Battery"
  | "Explosives"
  | "Copper cable"
  | "Iron stick"
  | "Iron gear wheel"
  | "Empty barrel"
  | "Electronic circuit"
  | "Advanced circuit"
  | "Processing unit"
  | "Engine unit"
  | "Electric engine unit"
  | "Flying robot frame"
  | "Rocket part"
  | "Rocket control unit"
  | "Low density structure"
  | "Rocket fuel"
  | "Nuclear fuel"
  | "Uranium-235"
  | "Uranium-238"
  | "Uranium fuel cell"
  | "Used up uranium fuel cell"
  | "Automation science pack"
  | "Logistic science pack"
  | "Military science pack"
  | "Chemical science pack"
  | "Production science pack"
  | "Utility science pack"
  | "Space science pack"
  | "Pistol"
  | "Submachine gun"
  | "Shotgun"
  | "Combat shotgun"
  | "Rocket launcher"
  | "Flamethrower"
  | "Land mine"
  | "Firearm magazine"
  | "Piercing rounds magazine"
  | "Uranium rounds magazine"
  | "Shotgun shells"
  | "Piercing shotgun shells"
  | "Cannon shell"
  | "Explosive cannon shell"
  | "Uranium cannon shell"
  | "Explosive uranium cannon shell"
  | "Artillery shell"
  | "Rocket"
  | "Explosive rocket"
  | "Atomic bomb"
  | "Flamethrower ammo"
  | "Grenade"
  | "Cluster grenade"
  | "Poison capsule"
  | "Slowdown capsule"
  | "Defender capsule"
  | "Distractor capsule"
  | "Destroyer capsule"
  | "Light armor"
  | "Heavy armor"
  | "Modular armor"
  | "Power armor"
  | "Power armor MK2"
  | "Portable solar panel"
  | "Portable fusion reactor"
  | "Personal battery"
  | "Personal battery MK2"
  | "Belt immunity equipment"
  | "Exoskeleton"
  | "Personal roboport"
  | "Personal roboport MK2"
  | "Nightvision"
  | "Energy shield"
  | "Energy shield MK2"
  | "Personal laser defense"
  | "Discharge defense"
  | "Discharge defense remote"
  | "Wall"
  | "Gate"
  | "Gun turret"
  | "Laser turret"
  | "Flamethrower turret"
  | "Artillery turret"
  | "Artillery targeting remote"
  | "Radar"
  | "Player";

const ITEM_NAMES: ItemName[] = [
  "Accumulator",
  "Active provider chest",
  "Advanced circuit",
  "Arithmetic combinator",
  "Artillery shell",
  "Artillery targeting remote",
  "Artillery turret",
  "Artillery wagon",
  "Assembling machine 1",
  "Assembling machine 2",
  "Assembling machine 3",
  "Atomic bomb",
  "Automation science pack",
  "Battery",
  "Beacon",
  "Belt immunity equipment",
  "Big electric pole",
  "Boiler",
  "Buffer chest",
  "Burner inserter",
  "Burner mining drill",
  "Cannon shell",
  "Car",
  "Cargo wagon",
  "Centrifuge",
  "Chemical plant",
  "Chemical science pack",
  "Cliff explosives",
  "Cluster grenade",
  "Coal",
  "Combat shotgun",
  "Concrete",
  "Constant combinator",
  "Construction robot",
  "Copper cable",
  "Copper ore",
  "Copper plate",
  "Crude oil",
  "Decider combinator",
  "Defender capsule",
  "Destroyer capsule",
  "Discharge defense",
  "Discharge defense remote",
  "Distractor capsule",
  "Efficiency module",
  "Efficiency module 2",
  "Efficiency module 3",
  "Electric engine unit",
  "Electric furnace",
  "Electric mining drill",
  "Electronic circuit",
  "Empty barrel",
  "Energy shield",
  "Energy shield MK2",
  "Engine unit",
  "Exoskeleton",
  "Explosive cannon shell",
  "Explosive rocket",
  "Explosive uranium cannon shell",
  "Explosives",
  "Express splitter",
  "Express transport belt",
  "Express underground belt",
  "Fast inserter",
  "Fast splitter",
  "Fast transport belt",
  "Fast underground belt",
  "Filter inserter",
  "Firearm magazine",
  "Flamethrower",
  "Flamethrower ammo",
  "Flamethrower turret",
  "Fluid wagon",
  "Flying robot frame",
  "Gate",
  "Green wire",
  "Grenade",
  "Gun turret",
  "Hazard concrete",
  "Heat exchanger",
  "Heat pipe",
  "Heavy armor",
  "Heavy oil",
  "Inserter",
  "Iron chest",
  "Iron gear wheel",
  "Iron ore",
  "Iron plate",
  "Iron stick",
  "Lab",
  "Lamp",
  "Land mine",
  "Landfill",
  "Laser turret",
  "Light armor",
  "Light oil",
  "Locomotive",
  "Logistic robot",
  "Logistic science pack",
  "Long-handed inserter",
  "Low density structure",
  "Lubricant",
  "Medium electric pole",
  "Military science pack",
  "Modular armor",
  "Nightvision",
  "Nuclear fuel",
  "Nuclear reactor",
  "Offshore pump",
  "Oil refinery",
  "Passive provider chest",
  "Personal battery",
  "Personal battery MK2",
  "Personal laser defense",
  "Personal roboport",
  "Personal roboport MK2",
  "Petroleum gas",
  "Piercing rounds magazine",
  "Piercing shotgun shells",
  "Pipe",
  "Pipe to ground",
  "Pistol",
  "Plastic bar",
  "Poison capsule",
  "Portable fusion reactor",
  "Portable solar panel",
  "Power armor",
  "Power armor MK2",
  "Power switch",
  "Processing unit",
  "Production science pack",
  "Productivity module",
  "Productivity module 2",
  "Productivity module 3",
  "Programmable speaker",
  "Pump",
  "Pumpjack",
  "Radar",
  "Rail",
  "Rail chain signal",
  "Rail signal",
  "Raw fish",
  "Red wire",
  "Refined concrete",
  "Refined hazard concrete",
  "Repair pack",
  "Requester chest",
  "Roboport",
  "Rocket",
  "Rocket control unit",
  "Rocket fuel",
  "Rocket launcher",
  "Rocket part",
  "Rocket silo",
  "Satellite",
  "Shotgun",
  "Shotgun shells",
  "Slowdown capsule",
  "Small electric pole",
  "Solar panel",
  "Solid fuel",
  "Space science pack",
  "Speed module",
  "Speed module 2",
  "Speed module 3",
  "Spidertron",
  "Spidertron remote",
  "Splitter",
  "Stack filter inserter",
  "Stack inserter",
  "Steam",
  "Steam engine",
  "Steam turbine",
  "Steel chest",
  "Steel furnace",
  "Steel plate",
  "Stone",
  "Stone brick",
  "Stone furnace",
  "Storage chest",
  "Storage tank",
  "Submachine gun",
  "Substation",
  "Sulfur",
  "Sulfuric acid",
  "Tank",
  "Transport belt",
  "Underground belt",
  "Uranium-235",
  "Uranium-238",
  "Uranium cannon shell",
  "Uranium fuel cell",
  "Uranium ore",
  "Uranium rounds magazine",
  "Used up uranium fuel cell",
  "Utility science pack",
  "Wall",
  "Water",
  "Wood",
  "Wooden chest",
];

export function getItemNames(): ItemName[] {
  return ITEM_NAMES.slice();
}
