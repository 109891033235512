import { ItemName } from "./item-names";

export type DesiredRateUnits = "per-second" | "per-minute";

export interface DesiredItemInit {
  itemName: ItemName;
  isEnabled: boolean;
  desiredRate: number;
  desiredRateUnits: DesiredRateUnits;
}

export class DesiredItem {
  itemName: ItemName;
  isEnabled: boolean;
  desiredRate: number;
  desiredRateUnits: DesiredRateUnits;

  constructor(init?: DesiredItemInit) {
    this.itemName = init?.itemName ?? "Transport belt";
    this.isEnabled = init?.isEnabled ?? true;
    this.desiredRate = init?.desiredRate ?? 1;
    this.desiredRateUnits = init?.desiredRateUnits ?? "per-second";
  }

  get desiredRateInItemsPerSecond(): number {
    return this.desiredRate / (this.desiredRateUnits === "per-second" ? 1 : 60);
  }

  toJSON(): DesiredItemInit {
    return {
      itemName: this.itemName,
      isEnabled: this.isEnabled,
      desiredRate: this.desiredRate,
      desiredRateUnits: this.desiredRateUnits,
    };
  }
}
