import { useAppSelector } from "../state/hooks";
import { NameDisplay } from "./name-display";

export function ProducersDisplay(): JSX.Element {
  const entries = useAppSelector((state) => state.output.producers);
  const producerCountTotal = entries.reduce(
    (producerCountTotal, { producerAmount: producerCount }) =>
      producerCountTotal + producerCount,
    0,
  );

  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Producer</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {entries.map(
          ({ producerName, producerAmount: producerCount }, index) => (
            <tr key={index}>
              <td>
                <NameDisplay name={producerName} />
              </td>
              <td>{producerCount}</td>
            </tr>
          ),
        )}
      </tbody>
      <tfoot className="table-group-divider">
        <tr>
          <th></th>
          <th>{producerCountTotal}</th>
        </tr>
      </tfoot>
    </table>
  );
}
